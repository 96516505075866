import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
// import { Footer, Header } from "."

const Layout: FC = () => (
  <BaseLayout>
    {/* <BaseLayout.Header>
      <Header />
    </BaseLayout.Header> */}
    <main>
      <Suspense>
        <Outlet />
      </Suspense>
    </main>
    {/* <BaseLayout.Footer>
      <Footer />
    </BaseLayout.Footer> */}
  </BaseLayout>
)

export { Layout }
